import axios from 'axios'

import { useMutation, useQuery } from '@tanstack/react-query'

import { baseUrl, basicAuth } from '@/configs/api'
import type { ChromebookData, ChromebookFormData } from '@/typings/chromebook'

type ChromebookDataResponse = {
  data: ChromebookData
}

const CHROMEBOOK_DISTRIBUTION_URL = `${baseUrl}/chromebook-tools/v1alpha1/chromebook-distribution`
export function useFetchChromebookDistirbutionByNPSN(
  serialNumber: string,
  npsn: string
) {
  return useQuery({
    queryKey: ['/serial-number', npsn],
    queryFn: async (): Promise<ChromebookDataResponse> => {
      try {
        const response = await axios.get<ChromebookDataResponse>(
          `${CHROMEBOOK_DISTRIBUTION_URL}/${serialNumber}`,
          {
            headers: {
              Authorization: basicAuth,
            },
          }
        )
        return response.data
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
        throw e
      }
    },
    enabled: !!serialNumber,
  })
}

export function useMutationAPIChromebookDistribution() {
  return useMutation({
    mutationFn: async (data: ChromebookFormData) => {
      return axios.post<ChromebookDataResponse>(
        `${CHROMEBOOK_DISTRIBUTION_URL}`,
        data,
        {
          headers: {
            Authorization: basicAuth,
          },
        }
      )
    },
  })
}
