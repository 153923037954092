import Image from 'next/image'
import Link from 'next/link'
import * as React from 'react'

import { Badge } from '@wartek-id/badge'
import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text'

export const Navbar = () => {
  return (
    <nav className="z-50 max-w-full w-screen top-0 bg-white py-3 sticky">
      <div className="max-w-screen-lg px-4 xl:px-4 2xl:px-0 xl:max-w-screen-xl m-auto flex items-center justify-between">
        <Link href="/" className="flex gap-4">
          <div>
            <Image
              src="/images/logo.png"
              alt="Kemdikbud Logo"
              width={44}
              height={44}
              className="mx-auto"
            />
          </div>
          <span className="flex items-center">
            <Badge>Chromebook Data</Badge>
          </span>
        </Link>

        <Link
          href="https://pusatinformasi.belajar.id/hc/en-us/sections/25143808097305"
          className="flex gap-2"
          target="_blank"
          rel="noreferrer noopener"
          title="Pusat Informasi atau Bantuan"
        >
          <Text variant="body-sm-bold">Pusat Informasi atau Bantuan</Text>
          <Icon>help_outline</Icon>
        </Link>
      </div>
    </nav>
  )
}
