import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import qs from 'query-string'

export const baseUrl = process.env.NEXT_PUBLIC_API_URL
export const basicAuth = `Basic ${process.env.NEXT_PUBLIC_BASIC_AUTH}`

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const paramsSerializer = (params): string =>
  qs.stringify(params ?? {}, { arrayFormat: 'none' })

export const api = (opts?: AxiosRequestConfig): AxiosInstance => {
  return axios.create({
    baseURL: baseUrl,
    headers,
    paramsSerializer,
    ...opts,
  })
}
