import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import {
  ChromebookFormData,
  FormState,
  FundingSource,
  Purpose,
} from '@/typings/chromebook'

type Step3DataWithEvidence = {
  is_used: true
  evidence_url: string
  purposes: Purpose[]
}

type Step3DataWithoutEvidence = {
  is_used: false
  reason: string
}

type Step3Data = Step3DataWithEvidence | Step3DataWithoutEvidence

type FormAction = {
  setDeviceData: (
    data: Pick<FormState, 'device_id' | 'user' | 'serial_number'>
  ) => void
  setNPSN: (npsn: string) => void
  setStep3Data: (data: Step3Data) => void
  setFundingSource: (fundingSource: FundingSource) => void
  setSchoolName: (school: string) => void
  getFormData: () => ChromebookFormData
}

const initialFormState: FormState = {
  serial_number: '',
  device_id: '',
  user: { name: '', email: '' },
  npsn: '',
  schoolName: '',
  evidence_url: '',
  funding_source: '',
  is_used: null,
  purposes: [],
  reason: '',
  submitted_by: '',
  count: 0,
}

export const useChromebookFormStore = create<FormState & FormAction>()(
  devtools((set, get) => ({
    ...initialFormState,

    // Actions
    setNPSN: (npsn) => set(() => ({ npsn })),
    setDeviceData: (data) =>
      set(() => ({
        serial_number: data.serial_number,
        device_id: data.device_id,
        user: data.user,
      })),
    setStep3Data: (data) =>
      set(() => ({
        ...data,
      })),

    setFundingSource: (fundingSource: FundingSource) =>
      set(() => ({
        funding_source: fundingSource,
      })),
    setSchoolName: (schoolName) => set(() => ({ schoolName })),
    getFormData: () => ({
      serial_number: get().serial_number,
      device_id: get().device_id,
      npsn: get().npsn,
      is_used: get().is_used,
      reason: get().reason,
      evidence_url: get().evidence_url,
      funding_source: get().funding_source,
      purposes: get().purposes,
      submitted_by: get().user.email,
    }),
  }))
)
