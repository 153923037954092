import Image from 'next/image'
import * as React from 'react'

export const Footer = () => {
  return (
    <nav className="z-50 max-w-full w-screen top-0 bg-white py-6">
      <div className="max-w-screen-xl m-auto flex justify-center gap-4">
        <div>
          <Image
            src="/images/logo-grayscale.png"
            alt="Kemdikbud Logo"
            width={44}
            height={44}
            className="mx-auto opacity-20"
          />
        </div>
      </div>
    </nav>
  )
}
