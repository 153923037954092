import type { ExtensionAPIResponses } from '@/typings/chromebook'

export const EXTENSION_ID = process.env.NEXT_PUBLIC_EXTENSION_ID

const mockResponses = {
  getSerialNumber: { serialNumber: '8BKV8C3' },
  getDeviceId: { deviceId: 'MOCK_DEVICE_ID_67890' },
  getUserIdentity: {
    name: 'John Doe',
    email: 'john.doe@example.com',
  },
}

export const callExtensionAPI = async <M extends keyof ExtensionAPIResponses>(
  method: M
): Promise<ExtensionAPIResponses[M]> => {
  if (process.env.NODE_ENV === 'development') {
    return mockResponses[method]
  }

  if (!chrome?.runtime) {
    throw new Error('Chrome runtime is not available.')
  }

  return new Promise((resolve, reject) => {
    chrome.runtime.sendMessage(
      EXTENSION_ID,
      { methodName: method },
      (response) => {
        if (chrome.runtime.lastError) {
          reject(new Error(chrome.runtime.lastError.message))
        } else {
          resolve(response)
        }
      }
    )
  })
}
