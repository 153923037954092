import * as React from 'react'

import { Footer } from '../Footer'
import { Navbar } from '../Navbar'

export const Layout = ({ children }) => {
  return (
    <div className="flex flex-col justify-between bg-surface-subdued min-h-screen">
      <Navbar />
      <main className="w-full max-w-screen-xl m-auto py-10">{children}</main>
      <Footer />
    </div>
  )
}
