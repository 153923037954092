/* eslint-disable no-console */
import { useRouter } from 'next/router'
import * as React from 'react'

import { Card } from '@wartek-id/card'
import { Spinner } from '@wartek-id/spinner'

import { callExtensionAPI } from '@/utils/extension'

import { useFetchChromebookDistirbutionByNPSN } from '@/apis/chromebook/useAPIChromebook'
import { Layout } from '@/components/Layouts'
import { Seo } from '@/components/Seo'
import { useChromebookFormStore } from '@/stores'

export const App = () => {
  const serialNumber = useChromebookFormStore((state) => state.serial_number)
  const npsn = useChromebookFormStore((state) => state.npsn)
  const setDeviceData = useChromebookFormStore((state) => state.setDeviceData)
  const {
    data: chromebookData,
    isFetching,
    isFetched,
    isError,
  } = useFetchChromebookDistirbutionByNPSN(serialNumber, npsn)
  const router = useRouter()

  React.useEffect(() => {
    if (!chromebookData && isFetched) router.replace('/step-1')
    if (isFetched && !isError) router.replace('/thank-you')
    if (!serialNumber && isFetched) router.replace('/step-1')
  }, [chromebookData, router, isFetched, isError, serialNumber])

  React.useEffect(() => {
    async function fetchData() {
      console.log('fetchData')
      try {
        // Use Promise.all to fetch all data in one go
        const [serialNumber, deviceId, userInfo] = await Promise.all([
          callExtensionAPI('getSerialNumber'),
          callExtensionAPI('getDeviceId'),
          callExtensionAPI('getUserIdentity'),
        ])

        // add loggging
        console.log('serialNumber', serialNumber)
        console.log('deviceId', deviceId)
        console.log('userInfo', userInfo)

        setDeviceData({
          serial_number: serialNumber.serialNumber,
          device_id: deviceId.deviceId,
          user: userInfo,
        })
      } catch (error) {
        console.error('Error calling extension:', error)
        router.replace('/step-1')
      }
    }

    fetchData()
  }, [router, setDeviceData])

  return (
    <Layout>
      <Seo templateTitle="Chromebook Data" />
      <div className="lg:max-w-screen-sm mx-auto">
        <Card className="min-h-[400px] flex items-center justify-center">
          {isFetching && <Spinner size="lg" />}
        </Card>
      </div>
    </Layout>
  )
}

export default App
